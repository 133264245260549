import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";

const url = Url_fetch();

export const userSignUp=async(user)=>{
    try{
        const signup = await axios.post(`/user/userSignUp`,{
            "name":user.name,
            "email":user.email,
            "mobile":user.mobile,
            "password":user.password
        });
        console.log("error",signup);
        return signup;

    }
    catch(error){
        return await error;
        
    }
}