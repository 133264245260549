import React from 'react'
import './style.css';
const Data_empty = () => {
  return (
    // <div className='data-empty-container'>
        <div className='inner-container'>
            <h2 className='text-message'> No registrations found.</h2>
        </div>
      
    // </div>
  )
}

export default Data_empty
