import React from "react";
import './tableStyle.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
const TableCustom = ({ tableColumns, tableRows,download_file ,type}) => {
  console.log("table row",tableRows);

  function isFileName(fileName) {
    // Regular expression to match a typical file extension
    const fileExtensionRegex = /\.[0-9a-z]+$/i;
    
    // Regular expression to match an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the string ends with a file extension and does not match the email regex
    return fileExtensionRegex.test(fileName) && !emailRegex.test(fileName);
}

// capitalise the first letter
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{backgroundColor:'#d3d3d3'}}>
          <TableCell style={{fontWeight:'800'}}>{'Number'}</TableCell>
            {tableColumns.map((column, index) => (
             
              <TableCell key={index} style={{fontWeight:'800'}}>{capitalizeFirstLetter(column)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, rowIndex) => (
            <TableRow key={rowIndex} style={{backgroundColor:'#fffafa'}}>
               <TableCell >{rowIndex + 1}</TableCell>
              {row.map((cell, cellIndex) => (
                
                // <TableCell key={cellIndex}>
                //   {isFileName(cell) ? <p onClick={()=>{download_file(cell)}} className="file-download-link">{'View'}</p> : cell}
                // </TableCell>    
                <TableCell key={cellIndex}>
                  {Array.isArray(cell)
                    ? cell.join(', ')
                    : isFileName(cell)
                      ? <p onClick={() => {download_file(cell)}} className="file-download-link">{'View'}</p>
                      : cell
                  }
                </TableCell>
   
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCustom;