import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const DropdownList = ({datalist,label,name,handleChange}) => {
  const handleChangeList=(e)=>{
        const select = e.target.value;
        const result = {[name]:select}
        handleChange(result);

  }
  return (
    <FormControl fullWidth sx={{ mb: 3 }}>
      {/* <InputLabel id="district-label">Group</InputLabel> */}

      <Select
        labelId="group-label"
        id="group-select"
        label='Select From List'
        name={name}
        fullWidth
        style={{width:'93%'}}
        onChange={handleChangeList}
        renderValue={(selected) => (
          <div style={{ textAlign: 'left' }}>{selected}</div>
        )}
      >
        <MenuItem value="">Select..</MenuItem>
        {datalist && datalist.map((item,index)=>(
            <MenuItem key={index} value={item} style={ { listStyleType: 'none'}}>
                {item}
            </MenuItem>
        ))}
        
      

      </Select>
    </FormControl>
  );
};

export default DropdownList;