import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import "./update_form.css";
import { formDataFetch_basedOnFormId } from "../../API/Fetch_data";
import WidgetDetailsDialog from "../../components/Dialog/Widget_datafetch";
import { update_form_Data } from "../../API/Update_data";
import Updating_Form_Widgets from "../../components/Dialog/UpdatingFormWidget";
import { delete_Widget_data } from "../../API/Delete_data";
import { ToastContainer, toast } from 'react-toastify';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const Form_Update = ({ formId }) => {
  const [widget, setWidget] = useState([]);
  const [widgetId, setWidgetId] = useState(""); //store the unique id of each widget
  const [openDialog, setOpendialog] = useState(false); // variable for open the dialog who is fetch the widget data,
  const [TypeofWidget, setTypeofWidget] = useState("");
  const [updateDialog, setUpdateDialog] = useState(false);
  
  const [widget_datavalues, setWidgetDataValues] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [fieldData, setFieldData] = useState([]);
  const [formTitleImage , setFormTitleImage] = useState('')


  useEffect(() => {
    console.log("FormIdFetch", formId);
    formdataFetch();
  }, []);

  const formdataFetch = async () => {
    const formdata = await formDataFetch_basedOnFormId(formId);

    console.log("formdata_______%%%%%", formdata.data.formResponse.data);
    const form = formdata.data;
    setFieldData(form.formResponse.data);
    setFormTitle(form.formResponse.form.formTitle);
    
    // Prepopulate widget state with fieldData
    const widgets = form.formResponse.data.map((widgetData, index) => {
      return {
        widget: (
          <div
            className="update-widget-style"
            //   className="drop-widget-style"
            //   dangerouslySetInnerHTML={{ __html: widgetData.widget }}
          >
            {renderDragContent(widgetData.widgetType)}
          </div>
        ),
        widgetid: widgetData._id,
        data: widgetData,
        widgetType: widgetData.widgetType,
      };
    });
    setWidget(widgets);
  };

  const dialogCloseandRemoveWidget = async (widgetid) => {
    await setWidget(widget.filter((item) => item.widgetid !== widgetid));
    await setOpendialog(false);
  };

  const renderDragContent = (type) => {
    switch (type) {
      case "text":
        return (
          <img
            src={require("../../images/txtInput.png")}
            draggable="false"
            alt="list"
            className="update-img-icon"
          />
        );
      case "radio":
        return (
          <img
            src={require("../../images/radioButton.png")}
            alt="list"
            draggable="false"
            className="update-img-icon"
          />
        );
      case "list":
        return (
          <img
            src={require("../../images/listNew.png")}
            draggable="false"
            alt="list"
            className="update-img-icon"
          />
        );
      case "date":
        return (
          <img
            src={require("../../images/calender_time.jpg")}
            draggable="false"
            alt="list"
            className="update-img-icon"
          />
        );
      case "file":
        return (
          <img
            src={require("../../images/fileIcon.png")}
            draggable="false"
            alt="list"
            className="update-img-icon"
          />
        );
      case "label":
        return (
          <img
            src={require("../../images/textnew.jpeg")}
            draggable="false"
            alt="list"
            className="update-img-icon"
          />
        );
      default:
        return null;
    }
  };

  const handleOndragStart = (e, widgetType) => {
    const draggedElemnt = e.target.cloneNode(true);
    e.dataTransfer.setData("text/html", draggedElemnt.innerHTML);
    e.dataTransfer.setData("widgetType", widgetType);
  };

  const handleDropWidget = (e) => {
    const widgetType = e.dataTransfer.getData("widgetType");
    setTypeofWidget(widgetType);
    const draggedContent = e.dataTransfer.getData("text/html");
    const newWidget = (
      <div
        className="update-drop-widget-style"
        dangerouslySetInnerHTML={{ __html: draggedContent }}
      ></div>
    );
    const widgetid = `field_${new Date().getTime()}`;
    setWidgetId(widgetid);
    setWidget([
      ...widget,
      { widget: newWidget, widgetid, data: null, widgetType: widgetType },
    ]);
    handleOpenDialogForAddingnew();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // handle open the dailog for addign new widget, during drag and drop
  const handleOpenDialogForAddingnew = () => {
    setOpendialog(true);
  };

  // close dialog for updating widget details
  const handleClose = () => {
    setOpendialog(false);
  };
  // avoid the closing widgets ,during out click and esc
  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClose();
  };
  // Open the dialog for editing
  const OpenDialogForEditing = async (widget) => {
    await setTypeofWidget(widget.widgetType);
    await setWidgetId(widget.widgetid);
    await setWidgetDataValues(widget.data);
    console.log("jhgdhsdf_________", widget.data);
    await setUpdateDialog(true);
  };

  const handleSaveWidgetDetails = (field, widgetname, id) => {
    console.log("save......****", field, widget, id);
    handleCloseDialog();
    const updateWidgets = widget.map((widget) =>
      widget.widgetid === id ? { ...widget, data: field } : widget
    );
    setWidget(updateWidgets);

    console.log("widgetjjjjj", widget);
  };
  //   const handleSaveWidgetDetails=()=>{
  //     console.log("hfjk");
  // }
  // __________________________________________________________
  // close the update dialog
  const UpdatedialogClose = () => {
    setUpdateDialog(false);
  };

  const handleUpdation = async () => {
    const formData = [];
    await widget.forEach((element) => {
      formData.push({
        _id: element.data._id,
        label: element.data.label,
        name: element.data.name,
        widgetType: element.widgetType,
        type: element.data.type,
        listItems: element.data.listArray,
        fileType: element.data.fileTypeArray,
        required: element.data.required,
        unique: element.data.unique,
        fileSize:element.data.filesize,
      });
    });

    const updateData = await update_form_Data(formId, formTitle, formData,formTitleImage);
    console.log("updatedata", updateData);
    if (updateData.data) {
      //alert(updateData.data.message);
      toast.success(updateData.data.message);
    } else {
      // alert("Internal Server Error, try again later");
      toast.error('Internal Server Error, try again later');

    }
  };

  // delete the widgets from the array and serverdb
  const delete_Widget_fromList = async (widgetid) => {
    await setWidget(widget.filter((item) => item.widgetid !== widgetid));
    const deleteWidget = await delete_Widget_data(widgetid);

    if (deleteWidget.data) {
      toast.success(deleteWidget.data.message);
      //alert(deleteWidget.data.message);
    } else {
      toast.error('Try after some times');
      // alert('Widget not found')
    }
    console.log(deleteWidget);
  };

  const handleChangeOfTitleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function(event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function() {
            const width = img.width;
            const height = img.height;

            if (width === 1260 && height === 300) {
                // Image has dimensions of 1260px width and 300px height
                console.log("Uploaded image has dimensions of 1260px width and 300px height");
                setFormTitleImage(file);
            } else {
                // Image does not have the required dimensions
                console.log("Uploaded image does not have dimensions of 1260px width and 300px height");
                // Reset the file input field
                e.target.value = ""; // Reset file input value to clear the selected file
            }
        };
    };

    reader.readAsDataURL(file);
};

  return (
    <div className="update-drag-drop-main-container">
      <div className="update-drag-container">
        {/* draggable elements */}
        <div
          className="update-drag-item-container"
          draggable
          onDragStart={(e) => handleOndragStart(e, "text")}
        >
          {/* <label>Text</label> */}
          <img
            src={require("../../images/txtInput.png")}
            draggable="false"
            alt="list"
            title="Text Field"
            className="update-img-icon"
          />
        </div>
        <div
          className="drag-item-container"
          draggable
          onDragStart={(e) => handleOndragStart(e, "radio")}
        >
          {/* <label>Radio</label> */}
          <img
            src={require("../../images/radioButton.png")}
            alt="list"
            title="Radio Button"
            draggable="false"
            className="update-img-icon"
          />
        </div>

        <div
          className="drag-item-container"
          draggable
          onDragStart={(e) => handleOndragStart(e, "list")}
        >
          {/* <label>List</label> */}
          <img
            src={require("../../images/listNew.png")}
            draggable="false"
            title="List"
            alt="list"
            className="update-img-icon"
          />
        </div>
        <div
          className="drag-item-container"
          draggable
          onDragStart={(e) => handleOndragStart(e, "file")}
        >
          {/* <label>File</label> */}
          <img
            src={require("../../images/fileIcon.png")}
            draggable="false"
            title="File"
            alt="list"
            className="update-img-icon"
          />
        </div>
        <div
          className="drag-item-container"
          draggable
          onDragStart={(e) => handleOndragStart(e, "date")}
        >
          {/* <label>Date Picker</label> */}
          <img
            src={require("../../images/calender_time.jpg")}
            draggable="false"
            title="Date & Time"
            alt="list"
            className="update-img-icon"
          />
        </div>
        <div
          className="drag-item-container"
          draggable
          onDragStart={(e) => handleOndragStart(e, "label")}
        >
          {/* <label>Date Picker</label> */}
          <img
            className="update-img-icon"
            src={require("../../images/textnew.jpeg")}
            draggable="false"
            alt="list"
            title="Label Text"
          />
        </div>
      </div>

      <div className="update-drop-main-container-outer">
        <div
          className="update-drop-container-style"
          onDrop={handleDropWidget}
          onDragOver={handleDragOver}
        >
          {/* <div> */}
        
        
          <div className="update-title-uploadimage">
              <input
                className="update-input-box-title"
                placeholder="Title"
                value={formTitle}
                onChange={(e) => {
                  setFormTitle(e.target.value);
                }}
              />
              <div className="update-button-wrap-container">
                <button className="update-upload-title-image-button" type="file"><input name="title-image"  accept="image/png,image/svg+xml" onChange={handleChangeOfTitleImage} type="file" /></button>
                <p className="info-text">*image with width 1260px height 300px</p>
              </div>
            
          </div>
          {widget.map((widgetData, index) => (
            <div className="update-drop-box-container" key={index}>
              <div className="update-widget-drop-map-container">
                {widgetData.widget}
                {
                  <DeleteIcon
                    onClick={() => {
                      delete_Widget_fromList(widgetData.data._id);
                    }}
                    style={{
                      marginLeft: "40px",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                  />
                }
              </div>
              {widgetData.widgetid && widgetData.data && (
                <div className="update-size-width-style">
                  {
                    <Button
                      onClick={() => {
                        OpenDialogForEditing(widgetData);
                      }}
                    >
                      <ModeEditIcon/>
                    </Button>
                  }
                  <Accordion
                    // defaultExpanded
                    className="update-size-width-style"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography className="update-text-design updatetext">
                        {widgetData.widgetType === "label"
                          ? widgetData.data.label
                          : widgetData.data.name.toUpperCase()}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="update-widget-data-accordian-view">
                        <p>
                          <span className="update-text-custom-style">
                            {" "}
                            Label :
                          </span>
                          {widgetData.data.label}
                        </p>

                        {widgetData.data.type && (
                          <p style={{ marginRight: "30px" }}>
                            {" "}
                            <span className="update-text-custom-style">
                              Type :
                            </span>
                            {widgetData.data.type}
                          </p>
                        )}
                        {widgetData.data.fileTypeArray &&
                          widgetData.data.fileTypeArray.length > 0 && (
                            <div>
                              <h4 className="update-list-Title">File Type</h4>
                              <ul className="update-ul-list-style-container">
                                {widgetData.data.fileTypeArray.map((list) => (
                                  <li>{list}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        {/* <p> <span className="text-custom-style">Required :</span>{widgetData.data.required}</p> */}
                      </div>
                      {widgetData.data.listArray &&
                        widgetData.data.listArray.length > 0 && (
                          <div>
                            <h4 className="list-Title">List Items</h4>
                            <ul className="ul-list-style-container">
                              {widgetData.data.listArray.map((list, index) => (
                                <li key={index}>{list}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </div>
          ))}
          {widget.length > 0 && (
            <div className="update-button-view-container">
              <Button variant="contained" style={{backgroundColor:'#413f3f',fontWeight:'600'}} onClick={handleUpdation}>
                Update
              </Button>
            </div>
          )}
        </div>
      </div>
      <WidgetDetailsDialog
        opendailog={openDialog}
        widgetType={TypeofWidget}
        handleClose={handleCloseDialog}
        handleSave={handleSaveWidgetDetails}
        widget_Id={widgetId}
        CloseAndRemove={dialogCloseandRemoveWidget}
      />
      <Updating_Form_Widgets
        opendailog={updateDialog}
        widgetType={TypeofWidget}
        handleClose={UpdatedialogClose}
        handleSave={handleSaveWidgetDetails}
        widget_Id={widgetId}
        widget_Data={widget_datavalues}
      />
       <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
  );
};

export default Form_Update;
