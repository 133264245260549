
import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";
const url = Url_fetch();
//  const token = sessionStorage.getItem('token');

export const Create_form=async(title,formData,generateUrl,formTitleImage,token)=>{
    console.log("url__________",formTitleImage,url,"token",token);

    try{
        
        const uploadData = await axios.post(

            `/createForms`,
            {
              formTitle: title,
              formUrl:generateUrl,
              formData: formData,
              formTitleImage:formTitleImage
            },
            {
              headers:{
                  Authorization:token,
             
                    "Content-Type": "multipart/form-data",
                    
              
              },
            }
          );
          console.log('Response',uploadData);
          return await uploadData;
    }
    catch(error){
        console.error("error%%%%",error);
        console.log(error);
        return await error;
    }
}
