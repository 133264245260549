import React from 'react'
import { ReactTyped } from 'react-typed'
import './closeStyle.css'
export const CloseReg = () => {
  return (
    <div className='outer-container'>
      <div className='inner-container'>
        <h1 className='sub-close-title'>Sorry Registration was{" "}<ReactTyped strings={["Closed !!"]} typeSpeed={100} loop /></h1>
        {/* <h1 className='title-close'> <ReactTyped strings={["Closed"]} typeSpeed={100} loop /></h1> */}
      </div>
    </div>
  )
}


