import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './tableStyle.css';
import { useNavigate } from 'react-router-dom';




export default function StickyHeadTable({rows}) {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const convertToDateFormat=(unformatdate)=>{
    const date = new Date(unformatdate);
    const formatDate = date.toLocaleDateString('en-GB');
    return formatDate;

}
const navigateToNext=(path,params)=>{
  navigate(path,{state:params})
}
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{backgroundColor:'#f8f8ff',boxShadow:'10px 0px 10px rgb(0,0,0,0.2)'}}>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 60 }}> Number</TableCell>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 170 }}> Name</TableCell>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 170 }}> Email</TableCell>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 170 }}> Form Title</TableCell>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 170 }}>Created</TableCell>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 170 }}> Count</TableCell>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 170 }}> Status</TableCell>
              <TableCell align="left" className='head-cell-text' style={{ minWidth: 170 }}> Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
             {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row.parentForm.userId.name }</TableCell>
                    <TableCell align="left">{row.parentForm.userId ? row.parentForm.userId.email : '-'}</TableCell>
                    <TableCell align="left">{row.parentForm.formTitle}</TableCell>
                    <TableCell align="left">{convertToDateFormat(row.parentForm.createdAt)}</TableCell> {/* Replace 'datePlaceholder' with actual date */}
                    <TableCell align="left">{row.participantCount}</TableCell>
                    <TableCell align="left">
                        {row.parentForm.status === '0' && (
                              <p style={{ color: 'green' }}>Active</p>
                            )}
                            {row.parentForm.status === '1' && (
                              <p style={{ color: 'red' }}>Closed</p>
                            )}
                            {row.parentForm.status === '2' && (
                              <p style={{ color: 'gray' }}>Deleted</p>
                            )}
                      </TableCell> {/* Replace 'statusPlaceholder' with actual status */}
                    <TableCell align="left">
                      {row.parentForm.status === '2' ?
                        <button className='table-delete-button'>View</button>
                      :
                      <button onClick={()=>{navigateToNext('/viewforms',{formId:row.parentForm._id,user:'admin'})}} className='table-view-button'>View</button>
                      }
                      </TableCell> 
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
