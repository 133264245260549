import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";

const url = Url_fetch();
const token = sessionStorage.getItem('token');

export const delete_Widget_data=async(widgetId)=>{
    console.log(token);
    try{
        const deleteWidget = axios.delete(`/deletechildform/${widgetId}`,{
            headers:{
                Authorization:token,
            }
        });

        console.log(deleteWidget);
        return await deleteWidget;
    }
    catch(error){
        console.error(error);
        return await error;
    }
}



//  delete the form from db
export const delete_Form_data_from_Db=async(id)=>{
   

    console.log("token__________",token);
    try{
        const formDataDelete = await axios.post(`/removeForm/${id}`,{
            headers:{
                Authorization:token,
            }
        });
        console.log(formDataDelete);
        return  formDataDelete;
    }
    catch(error){
        console.error("error",error);
        return await error;
    }
}