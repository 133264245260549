import axios from "axios";
import { Url_fetch } from "../UrlConnection/Url";

const url = Url_fetch();

const token = sessionStorage.getItem('token');


export const update_form_Data =async(formid,formTitle,formData,formTitleImage)=>{
    const Token = sessionStorage.getItem('token');
    try{
        const update = await axios.post(`/updateform/${formid}`,{
            formTitle:formTitle,
            formData:formData,
            formTitleImage:formTitleImage,
        },
        {
            headers:{
                Authorization:Token,

                "Content-Type": "multipart/form-data",
            },
          }
        
        )

        console.log("updateData___",update);
        return await update;
    }
    catch(error){
        console.error("Error",error);
        return await error;
    }
}


// __________________________update the stopResponse state__________

export const update_stopResponse_ParentForms = async(id,newStopResponse)=>{
    const Token = sessionStorage.getItem('token');
    try{
      const updateResponse = await  axios.put(`/responseState/${id}`,{
                                    stopResponse: newStopResponse 
                                },
                                {
                                    headers:{
                                        Authorization:Token 
                                    }
                                })
        console.log(updateResponse);
        return await updateResponse;
    }
   catch(error){
    console.log("Error",error);
    return await error;

   }
}

// forgot password__________________________________________________________________________________
export const forgot_Password=async(email)=>{
    try{
        const forgot = await axios.post(`/password/forgot-password`,{
            "email":email
        });
        console.log(forgot);
        return forgot;
    }
    catch(error){
        console.error(error);
        return await error;
    }
}

export const VerifyOTP=async(otp,email)=>{
    try{
        const verify = await axios.post(`/password/verify-otp`,{
            "email":email,
            "otp":otp
        })
        console.log(verify);
        return await verify;
    }
    catch(error){
        console.log(error);
        console.error(error);
        return await error;
    }
}


export const resetPassword_On_Server=async(email,password)=>{
    try{
        const reset = await axios.post(`/password/reset-password`,{
            "email":email,
            "newPassword":password
        })
        console.log(reset)
        return reset;
    }
    catch(error){
        console.log(error);
        console.error(error);
        return await error;
    }
}