import React, { useState } from 'react'
import './loginuser.css';
import { CardContent, TextField, Typography } from '@mui/material';
import Login_Box from '../../components/containers/LoginBox/Login_Box';
import { isMobile } from 'react-device-detect';
import Button19 from '../../components/Buttons/Button19';
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';

const UserLogin = () => {
  const [user_id , setUser_Id] =  useState('');
  const [passsword , setPassword] = useState('');

  const navigate = useNavigate();

  return (
    <>
   
      {isMobile ? 
      (
      <div className='login-container-mobile'>
        <Login_Box  />
        <p className='mobile-text'>If you don't have account ? <span onClick={()=>{navigate('/userSignup')}} className='mobile-signup-text' >Sign Up</span></p>
      </div>
      )
      :
      (
        <div className='login-outer-container'>
      <Card className='login-container'>
        <Card  className='login-inner-left-container'>
          <CardContent>
          <Login_Box/>
          </CardContent>
          
        </Card>
        <Card className='login-inner-right-container'>
          <CardContent>
          <div className='login-inner-right-img-textContainer'>
              <img src={require('../../images/LOGO_ICTAK-ENG-ALT-White-Text.png')} style={{width:'100%',height:'100px'}} />
              <p className='signup-text'>Sign up here and create your forms</p>

            <Button19 ClickButton={()=>{navigate('/userSignup')}}/>
            </div>

          </CardContent>
           
        </Card>
        
      </Card>
      </div>
      )
    }
    
    </>
  )
}

export default UserLogin
