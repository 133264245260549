export const validateEmail =(email)=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const validateMobile =(mobile)=>{
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(mobile);
};

export const requiredText =(name)=>{
    return name.trim()!== '';
}

export const validateText = (text) => {
    // Example: Validate if text contains numeric or special characters
    const regex = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return !regex.test(text);
  };