import React, { useState } from 'react'
import { useEffect } from 'react'
import { AdminTokenVerify } from '../API/SperAdmin'
import { useNavigate, useNavigation } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../AuthContext/AuthContext'


const AdminGurd = ({component}) => {
  const token = sessionStorage.getItem('token');
    const [status , setStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{

      CheckToken();
    },[])

    const CheckToken =async()=>{
      console.log("token_client_vghh",token);
      const tokenCheck = await AdminTokenVerify(token);
      console.log("clientside____",tokenCheck);
      if(tokenCheck.data){
          setStatus(tokenCheck.data);
      }
      else{
        navigate('/admin')
      }
  }
  return status ? <>{component}</>:<></>
}

export default AdminGurd
