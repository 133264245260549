
import './App.css';
import FormCreation from './Form/DragandDrop/Form_Creation';
import { Navigate, Route, Routes } from 'react-router-dom';
import GeneratedForm from './Form/GenerateForm/GenerateForm';
import InvalidRoute from './container/InvalidRoute/InvalidRoute';
import UserLogin from './container/Login/UserLogin';
import Home from './container/Home/Home';
import ViewForms from './Form/ViewForms/ViewForms';
import SubmisionResponse from './Form/Submission/SubmisionResponse';
import SignUp from './container/SignUp/SignUp';
import Form_Update from './Form/UpdateForm/Form_Update';
import { CloseReg } from './container/CloseReg/CloseReg';
import Authgurds from './gurds/Gurds';
import AdminHome from './supperAdmin/supperAdminHome/AdminHome';
import Reg_View from './supperAdmin/RegistrationView/Reg_View';
import StickyHeadTable from './components/StickyheaderTable/StickyheaderTable';
import ListOutAllForms from './supperAdmin/ListOutAllForms/ListOutAllForms';
import SupperAdminLogin from './supperAdmin/SupperAdminLogin/SupperAdminLogin';
import { useContext } from 'react';
import AuthContext from './AuthContext/AuthContext';
import AdminGurd from './gurds/AdminGurd';
import MultipleSelectList from './components/MultipleSelectList/Multiple_select_list';


function App() {
 
  const {token} = useContext(AuthContext);
  console.log("token kitttipot=ye__________",token);

  return (
    // <div className="App">
     <Routes>
        <Route path='/' element={<UserLogin/> } />
       
        <Route path='/createform' element={<Authgurds component={ <FormCreation/>}/>} />

        <Route path='/form/:form_id' element={<GeneratedForm/>}/>
       
        <Route path='/home/:token' element={ <Home/>}/>
        {/* <Route path='/viewforms' element={<Authgurds component ={ <ViewForms/>}/>} /> */}
        <Route path='/viewforms' element={<Authgurds component={  <ViewForms/>}/>}/> 

        <Route path='/submitresponse' element={<SubmisionResponse/>}/>

        <Route path='/userSignup' element={<SignUp/>} />

        {/* <Route path='/thankyou' element={<SubmisionResponse/>} /> */}

       <Route path='/close' element={<CloseReg/>} />

        <Route path='/adminhome' element={<AdminGurd component={<ListOutAllForms/>}/>}/> 
       {/* <Route path='/adminhome' element={<Navigate replace to='/admin'/>}/> */}
        <Route path='/multiplelist' element={<MultipleSelectList/>} />
      <Route path='/admin' element={<SupperAdminLogin/>} />
      
      <Route path='/*' element={<InvalidRoute titlemessage={'Oops....Error 404'} message={'Sorry, but the page you are looking for doesn’t exist'}/>} />

      
     </Routes>
    // </div>
  );
}

export default App;
