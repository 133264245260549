import  React,{useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './supper_loginStyle.css';
import { supperAdminLogin } from '../../API/Login';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../AuthContext/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import { validateEmail } from '../../Validation/Validation';
const SupperAdminLogin = () => {

  const {updateToken} = useContext(AuthContext);

  const [admin,setAdmin] = useState({email:'',password:''});

  const navigate = useNavigate();
  const [error,setErrors] = useState({})


  const handleOnChange=(e)=>{
    const {name,value} = e.target;
    setErrors({...error,[name]:''})
    if(name === 'email'){
      const validate = validateEmail(value);
      console.log("hghds",validate)
      if(validate === false){
        setErrors({...error,[name]:'Inavlid email'})
      }
    }
    console.log("email",name,"password",value);
    setAdmin({...admin,[name]:value});
  }


  const handleSubmit =async () => {
    console.log("new data",admin);
    if(admin.email==''){
      setErrors({...error,email:'Required'});
    }
    else if(admin.password === '')
    {
      setErrors({...error,password:'Required'});
    }
    else{
      if (Object.values(error).every(error => error === '')){
      let email = admin.email;
      let password = admin.password;
  
      const responseLogin = await supperAdminLogin(email,password);
      if(responseLogin.data){
        console.log('Clien_____,',responseLogin.data.token);
        let token = responseLogin.data.token;
        sessionStorage.setItem('token',token);
        localStorage.setItem('email',responseLogin.data.name);
        updateToken(token);
        navigate('/adminhome'); 
      }
      else{
        toast.error(responseLogin.response.data.message)
      }
    }
    }
  };

  const defaultTheme = createTheme();


  return (
    // <div className='su-login-container'>

<>

    <ThemeProvider  theme={defaultTheme}>
      <Container style={{backgroundColor:'white' ,  boxShadow: '5px 5px 5px 5px rgb(0,0,0,0.2)', }} component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            marginLeft:5,
            marginRight:5,

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // borderWidth:'1px',
            // borderColor:'#000',
            // borderStyle:'solid'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#9932cc',marginTop:'30px' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Admin Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error ={!!error.email}
              helperText={error.email}
              onChange={handleOnChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error ={!!error.password}
              helperText={error.password}
              onChange={handleOnChange}
              autoComplete="current-password"
            />
           
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
           
          </Box>
          <div style={{height:'50px'}}>

          </div>
        </Box>
      
      </Container>
    </ThemeProvider>
    <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      </>
    // </div>
  )
}

export default SupperAdminLogin
