import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { delete_Form_data_from_Db } from '../../API/Delete_data';
import './card_form.css';

const Card_Form = ({ClickView,Title,Id,DeleteForm_Data}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
const handleDelete=()=>{
  // const deleteform = delete_Form_data_from_Db(Id);
  // console.log(deleteform);
  DeleteForm_Data(Id);
}
const handleClose=()=>{
  setAnchorEl(null);
}
 
  return (
    <Card className='card-main-container'>
        <CardMedia
        // style={{backgroundColor:'ButtonHighlight'}}
        sx={{ height: 100,width:100 }}
        image={require('../../images/form.png')}
        title="form"
      />
      <CardContent className='card-content' style={{width:'100%'}}>
      <p className='card-form-title' >{Title}</p>
     
      <div className='view-text-icon-div' >
      <p className='card-form-view-typography'  onClick={ClickView}>View</p>
      <IconButton
    
        aria-label="more"
        id="long-button"
        // aria-controls={open ? 'long-menu' : undefined}
        // aria-expanded={open ? 'true' : undefined}
        // aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
       
      >
        {/* {options.map((option) => ( */}
        
          <MenuItem onClick={handleDelete}  >
          Delete
          </MenuItem>
        {/* ))} */}
      </Menu>
      </div>
      </CardContent>  
    </Card>
  )
}

export default Card_Form;
