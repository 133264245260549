import React, { useEffect, useRef, useState } from "react";
import "./form_setting.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { formDataFetch_basedOnFormId, formData_fetch_from_Server } from "../../API/Fetch_data";
import { update_stopResponse_ParentForms } from "../../API/Update_data";
import { delete_Form_data_from_Db } from "../../API/Delete_data";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/Dialog/Confirm_Dialog";
import { ToastContainer, toast } from 'react-toastify';

const Form_Settings = ({formId}) => {
  const Token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const [url,setUrl] = useState('');
    const [stopResponse, setStopResponse] = useState(null);
    const [copied , setCopied]=useState(false);
    const[openConfirmPopup ,setOpenConfirmPopup] = useState(false);

    const state = useRef();


useEffect(()=>{
    fordataFetch_from_DB();
},[])

const handleSwitchChange = async (event) => {
  const newStopResponse = event.target.checked;
  console.log("stateupdate",newStopResponse);
  state.current = newStopResponse;
  setStopResponse(newStopResponse); // Update the state immediately
  

  try {
    // Make an API call to update the stopResponse state
   const updateState = await update_stopResponse_ParentForms(formId,state.current);
   console.log(updateState);

    // Handle success if needed
  } catch (error) {
    console.error('Error updating stopResponse state:', error);
    // Handle error if needed
  }
};


const fordataFetch_from_DB = async()=>{

    const formData = await formData_fetch_from_Server(formId,Token);
    console.log("form_____data__get",formData);
    if(formData.data){
        const url_form = formData.data.formUrl + formData.data._id;
        setUrl(url_form);
        setStopResponse(formData.data.stopResponse);
       
    }
    else{
        setUrl('empty');
    }
}
    // copy the url
    const handleCopyUrl =()=>{
        navigator.clipboard.writeText(url);
        setCopied(true);
    };


    // handle delete the form data and corresponding widgtets
    const handleDeleteFormdata =async()=>{
      const deleteData = await  delete_Form_data_from_Db(formId);
      console.log("deletedata",deleteData);
      if(deleteData.data){
        toast.success(deleteData.data.message);
        navigate(`/home/${Token}`);
      }
      else{
        toast.error('Somthing went wrong, try again after sometimes');
      }
      
    }

  return (
    <div className="settings-outer-container">
      <div className="settings-inner-container">
        <div
          className="box-settings"
          
        >
          <div>
            {" "}
            <h3 className="text-inside-box">Stop Response </h3>
          </div>

          <FormControlLabel
          
            control={
              <Switch
              checked={stopResponse}
              onChange={handleSwitchChange} // Add the onChange handler
              />
            }
            label=""
          />
        </div>
        <div
          className="box-settings"
          
        >
          <div>
            {" "}
            <h3 className="text-inside-box">Delete the form </h3>
          </div>
            <button className="deleteButton" onClick={()=>{setOpenConfirmPopup(true)}} >
          <DeleteIcon className="deleteIcon" />
          </button>
        </div>

        <div  className="box-settings">
            <div className="url-div">
                {/* <p >Created form URL ,</p> */}
                <p className="url-text-style" >{url}</p>
            </div>
            <button className="copy-button" onClick={handleCopyUrl}>{copied ? "Copied!":"Copy URL"}</button> 
        </div>

      </div>
      <ConfirmationDialog
        open={openConfirmPopup}
        message={'Are you sure you want to delete this form?'}
        onClose={()=>{setOpenConfirmPopup(false)}}
        onConfirm={handleDeleteFormdata}
      />
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      
    </div>
  );
};

export default Form_Settings;
