import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import axios from 'axios';
import { VerifyOTP, resetPassword_On_Server } from '../../API/Update_data';
import { useState } from 'react';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function PasswordReset({open,handleClose,email}) {

    const [verifiedStatus , setverifiedStatus] = useState(false);
    // const [buttonLabel , setButtonLabel] = React.useState('Verify');
    const [otp,setOtp] = useState(''); // store the input otp for verification
    const [passsword , setPassword] = useState({
        newpassword:'',
        confirmpassword:''
    })

    const [errors , setErrors] = useState({});


const handleOTPVerification=async()=>{
    const otpVerification = await VerifyOTP(otp,email);
    if(otpVerification.data){
        alert(otpVerification.data.message);
        setverifiedStatus(true);
    }
    else{
        alert(otpVerification.response.data.error);
    }
    console.log(otpVerification);
}

const handleChangeOTP=(e)=>{
    const otpfetch = e.target.value;
    console.log("otp",otpfetch);
    setOtp(otpfetch);
}

const handleChangeOfPassword=(e)=>{
    const {name , value} = e.target;
    setErrors({...errors ,[name]:''});
    if(name === "newpassword" && value.length <= 5){
        setErrors({...errors,[name]:'Password must be atleast 5 letters'});
    }
     console.log("name",name,"value",value);
     if (name === "confirmpassword" && passsword.newpassword !== value) {
        setErrors({...errors, [name]: 'Password mismatch'});
    }
     setPassword({...passsword,[name]:value})
}

const resetPassword=async()=>{
    if(passsword.newpassword ==='',passsword.confirmpassword === ''){
        setErrors({...errors,newpassword:'Required',confirmpassword:'Required'})
    }

    if(Object.values(errors).every(error => error === '')){
        const passwordreset = await resetPassword_On_Server(email,passsword.newpassword);
        console.log("responseClient",passwordreset);
        if(passwordreset.data){
            alert(passwordreset.data.message);
            setverifiedStatus(false);
            handleClose();
        }
        else{
            alert(passwordreset.response.data.error);
        }
       
    }
}

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
         Reset Password
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{display:'flex' ,flexDirection:'column'}}>
          <Typography gutterBottom>
            Please enter the OTP here 
          </Typography>
          <TextField id="filled-basic" label="OTP" variant="filled"  onChange={handleChangeOTP} style={{marginBottom:'10px'}}/>
          <Button onClick={handleOTPVerification} variant='contained' color={verifiedStatus?'success':'secondary'}>{verifiedStatus === true ? 'Verified' : 'Verify'}</Button>

        </DialogContent>
        {verifiedStatus&&
        <DialogContent style={{display:'flex',flexDirection:'column',marginTop:'10px'}}>
        <TextField id="standard-basic"
            name="newpassword"
            onChange={handleChangeOfPassword}
            label="NewPassword"
            variant="standard"
            error={!!errors.newpassword}
            helperText={errors.newpassword}
            style={{paddingTop:'10px',marginTop:'20px'}} 
        />

        <TextField id="standard-basic" 
            name="confirmpassword" 
            onChange={handleChangeOfPassword} 
            label="Confirm NewPassword" 
            variant="standard" 
            error={!!errors.confirmpassword}
            helperText={errors.confirmpassword}
            style={{paddingTop:'10px',marginTop:'20px'}} 
        />
        </DialogContent>
        }
        <DialogActions>
            {verifiedStatus&&
          <Button autoFocus onClick={resetPassword}>
            Save changes
          </Button>}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}