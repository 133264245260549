import React from 'react'
import './submitStyle.css';
import { ReactTyped } from 'react-typed'
const SubmisionResponse = () => {
  return (
    <div className='submit-response-container'>
        <div className='submit-response-inner-container'>
            <img className='img-style' src={require('../../images/tick1.png')}  />
            <h2 className='title-text'>Thank You !!</h2>
            <h4 className='subtitle-text'><ReactTyped strings={["Information has been successfully saved"]} typeSpeed={100} loop /></h4>

        </div>
      
    </div>
  )
}

export default SubmisionResponse
