import "./style.css";
import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useLocation } from "react-router-dom";
import DropdownList from "../../components/DropdownList/Dropdownlist";
import RadioButton from "../../components/Radio/RadioButton";
import DateAndTimePicker from "../../components/Date/DateTimePicker";
import { useParams } from "react-router-dom";
import { Url_fetch } from "../../UrlConnection/Url";
import { CloseReg } from "../../container/CloseReg/CloseReg";
import { ToastContainer, toast } from "react-toastify";
import MultipleSelectList from "../../components/MultipleSelectList/Multiple_select_list";

const GeneratedForm = () => {
  const token =  sessionStorage.getItem("token");
  // server urlfetch
  const url = Url_fetch();
  const { form_id } = useParams();
  console.log("Form id", form_id);

  const navigate = useNavigate();

  const [participantdata, setParticipantData] = useState([]);
  const [fieldData, setFielddata] = useState([]);
  const [formtitle, setFormtitle] = useState("");
  const [files, setFiles] = useState([]);
  const [registration,setRegistration] = useState([]);
 const [response , setResponse] = useState(null);
const [formTitleImageUri , setFormTitleImgeUri] = useState(''); //store the formTitle image uri

  useEffect(() => {
    console.log("hiiiiii");
    RegistrationDataFetch();
  }, []);

  const RegistrationDataFetch = async () => {
    try {
      const response = await axios.get(`/forms/${form_id}`);
      const formData = response.data;
      console.log("datafetch",formData);
      setFormtitle(formData.formResponse.form.formTitle); //store the title of the form
      setFormTitleImgeUri(formData.formResponse.form.formTitleImageLink);
      setResponse(formData.formResponse.form.stopResponse);
      setFielddata(formData.formResponse.data); //it is an array to store all widget data
      const fieldDataFetch = formData.formResponse.data;
      const newFieldData = fieldDataFetch.map(field=>({[field.name]:'',required:field.required}));
       setRegistration(newFieldData);
      console.log("Widget data", formData.formResponse.data);
    } catch (error) {
      console.error(error);
     
      
    }
  };

  // textfield data
  const handleChangeText = (e) => {
    const { name, value } = e.target;
    const updateArray = registration.map((item)=>{
      if(Object.keys(item)[0]===name){
        return{...item , [name]:value,required:item.required};
      }
      return item;
    });
    setRegistration(updateArray);
    // setParticipantData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));
    console.log("name,value", name, value);
    console.log("participantdata", participantdata);
  };

  // gender radio value
  const handleChangeOfRadio = (Gender) => {
    console.log("Gender", Gender);
    const updateArray2 = registration.map((item)=>{   //updateArray2 contan the name,required property
      if(Object.keys(item)[0]===Object.keys(Gender)[0]){
        return{...item , [Object.keys(Gender)[0]]:Object.values(Gender)[0],required:item.required};
      }
      return item;
    });
    setRegistration(updateArray2);
    // setParticipantData((prevData) => ({
    //   ...prevData,
    //   [Object.keys(Gender)[0]]: Object.values(Gender)[0],
    // }));
    // [Object.keys(genderResponse)[0]]: Object.values(genderResponse)[0]
  };

  // handle the data of list
  const handleDropdownChange = (listSelect) => {
    console.log("Select", listSelect);
    const updateArray1 = registration.map((item)=>{
      if(Object.keys(item)[0]===Object.keys(listSelect)[0]){
        return{...item , [Object.keys(listSelect)[0]]:Object.values(listSelect)[0],required:item.required};
      }
      return item;
    });
    setRegistration(updateArray1);
    // setParticipantData((prevData) => ({
    //   ...prevData,
    //   [Object.keys(listSelect)[0]]: Object.values(listSelect)[0],
    // }));
  };

  // handle date and time
  const handleDateandTime = (date, name) => {
    console.log("date and time", name);
    const newDate = date["$d"].toISOString();
    console.log("newDatefetch", newDate);

    const updateArraydate = registration.map((item)=>{
      if(Object.keys(item)[0]===name){
        return{...item , [name]:newDate,required:item.required};
      }
      return item;
    });
    setRegistration(updateArraydate);

    // setParticipantData((prevData) => ({
    //   ...prevData,
    //   [name]: newDate,
    // }));
  };

  const handleChangeOf_files = async (e,filesize) => {
    const { name, value } = await e.target;
    const selectedFile = await e.target.files[0];
    console.log("nameee", name);

    const maxSizeInBytes = parseInt(filesize) * 1024 * 1024;
    if(selectedFile){
      if(selectedFile.size > maxSizeInBytes){
        alert(`File size exceeds the limit of ${filesize} MB`);
        e.target.value = null;
      return;
      }
      else{
    

    const updateArrayfile = registration.map((item)=>{
      if(Object.keys(item)[0]===name){
        return{...item ,[name]:selectedFile,required:item.required};
      }
      return item;
    });
    setRegistration(updateArrayfile);

    setFiles((prevData) => ({
      ...prevData,
      [name.toLowerCase()]: selectedFile,
    }));

    console.log("participantdata_____", files);
  }
}
  };

// handle the multiple select list 
const handleMultipleListSelect=(list)=>{
  console.log("selectedListItemsonForm_______",list);
  console.log("reg",registration);
  const updateArray = registration.map((item)=>{
    if(Object.keys(item)[0] === Object.keys(list)[0]){
        return{...item , [Object.keys(list)[0]]:Object.values(list)[0]}
    }
    else {
      // If not, return the item unchanged
      return item;
    }
  });
  setRegistration(updateArray);
  console.log("updatearray_______",updateArray);
  
}

  const renderFormElement = (field) => {
    switch (field.widgetType) {
      case "text":
      case "email":
        return (
            <TextField
            className="event-textfield-style"
            label={field.label}
            variant="outlined"
            name={field.name}
            type={field.type}
            value={participantdata[field.name]}
            onChange={handleChangeText}
          />

        );

      case "radio":
        return (
          <RadioButton
            name={field.name}
            label={field.label}
            listdata={field.listItems}
            handleRadioChange={handleChangeOfRadio}
          />
        );

      case "list":
        return (
          <>
          {field.type === 'single'?
            <DropdownList
              datalist={field.listItems}
              label={field.label}
              name={field.name}
              handleChange={handleDropdownChange}
          />
          :
          <MultipleSelectList
            datalist={field.listItems}
            name={field.name}
            handleChangeList={handleMultipleListSelect}

          />
        }
          </>
  
        );

      case "date":
        return (
          <>
            {field.type === "date" ? (
              <TextField
                className="event-textfield-style"
                //   label={field.label}
                variant="outlined"
                name={field.name}
                value={participantdata[field.name]}
                type={field.widgetType}
                onChange={handleChangeText}
                required
              />
            ) : (
              <DateAndTimePicker
                onDateTimeChange={handleDateandTime}
                name={field.name}
              />
            )}
          </>
        );
        case "label":
          return (
            <>
            {field.type ==='title'?
            (<h2 className="text-label-h3" style={{fontSize:'25px' , fontWeight:'700'}} >{field.label}</h2>)
            :
            field.type === 'sub-title'?(<h3 className="text-label-h3" style={{fontSize:'20px' , fontWeight:'600'}} >{field.label}</h3>)
            :
            (
              <div style={{ width:'100%',margin:0,padding:0}}>
                 <p className="text-label-h3" style={{fontSize:'16px'}}>{field.label}</p>
               </div>
            )}
          
            </>
          );

      case "file":
        return (
          <>
            <TextField
            className='event-textfield-style'
          //   label={field.label}
            variant='outlined'
            name={field.name}
            // value={participantdata[field.name]}
            type={field.widgetType}
            // onChange={handleChangeText}
            onChange={(e)=>{handleChangeOf_files(e,field.fileSize)}}
            accept={field.fileType.map((type) => (type === "image" ? ".jpg, .png, .jpeg" : `.${type}`)).join(", ")}
             
            />
            {/* <input
              // className='event-textfield-style'
              style={{ width: "100%",border:'1px',borderColor:'black' }}
              type="file"
              name={field.name}
              accept={field.fileType.map((type) => (type === "image" ? ".jpg, .png, .jpeg" : `.${type}`)).join(", ")} // Specify allowed file types
              onChange={handleChangeOf_files}
            /> */}
            <p> Type : *{field.fileType.join(', ')}</p>
          
          </>
        );

      default:
        return null;
    }
  };

  const Register_Form = async () => {
    console.log("files", files);
    console.log(".0", participantdata);
    console.log("NewREgFormdata########",registration);
    const emptyRequiredField = await registration.filter(item=>item.required && item[Object.keys(item)[0]] === '');
    if(emptyRequiredField.length > 0){
      toast.error('Please fill in all required fields');
      //alert("Please fill in all required fields");
    }
    else{
      const formData = await new FormData();
      formData.append(
        "dynamicFields",
        JSON.stringify({
          registration,
          form_id,
        })
      );
  
      Object.keys(files).forEach((fieldName) => {
        const file = files[fieldName];
        formData.append(fieldName, file);
      });
  
      console.log("formdataaa ####", formData);
      try {
        const response = await axios.post(                 //post request api for storing the data
          `/participant/saveParticipant`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              
            },
          }
        );
        console.log(
          "########",
          `dynamicFields: {
          ${participantdata},
          "form_id":${form_id}
        }`
        );
        console.log(response);
        //alert("Registration Successfull");
        toast.success('Registration Successfull');
        navigate('/submitresponse')
      } catch (error) {
        console.log("errrr%%%%%%",error);
        console.error(error);
        //alert(error.response.data.error);
        toast.error(error.response.data.error);
      }
    }
  };

  return (
    <>
    {response && response === true?
    <CloseReg/>
    :
    <div className="participants-Registration-container">
      
      <div className="titleImageContainer">
            <img className="titleImage" src={formTitleImageUri} alt={formTitleImageUri}/>
      </div>

      <div className="event-parti-title-container">
        {/* <Typography
          style={{ paddingRight: "30px", fontSize: "40px", fontWeight: "500" }}
        > */}
        <h1 className="generated-form-title">{formtitle}</h1>
       
            {/* <img src={formTitleImageUri} alt={formTitleImageUri}/> */}
          
        {/* </Typography> */}
      </div>

      <div className="event-partic-form-container">
        {fieldData.map((field, index) => (
          <div className="event-partic-textbox-container" key={index}>
             
            <div style={{display:'flex',flexDirection:'row'}}>
              {field.widgetType !== 'label' &&
            <label className="label">{field.label}{field.required === true &&
              <span style={{color:'red',paddingLeft:'10px'}}>*</span>
              }</label> 
            }
            
            </div>
           

            {renderFormElement(field)}
          </div>
        ))}
      </div>
      <div className="event-partic-button-view">
        <Button
          variant="contained"
          className="event-partici-button"
          onClick={Register_Form}
        >
          Register
        </Button>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
    }
     
    </>
    
  );
};

export default GeneratedForm;
