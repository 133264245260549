import React, { useEffect, useState } from "react";
import { TextField, Typography, useStepContext } from "@mui/material";
import "./loginbox.css";
import Buttton18 from "../../Buttons/Buttton18";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Email_Dialog_Box from "../../Dialog/Email_dialog";
import PasswordReset from "../../Dialog/PasswordReset";
import Cookies from "js-cookie";
import { login_User } from "../../../API/Login";
import { forgot_Password } from "../../../API/Update_data";

import AuthContext from "../../../AuthContext/AuthContext"; //for storing auth token
import { useContext } from "react";

const Login_Box = () => {
  const {updateToken} = useContext(AuthContext);


  const navigate = useNavigate();

  const [userId , setUserId] = useState('');
  const [password,setPassword] = useState('');

  const [openEmail , setOpenEmail] = useState(false);

  const [resetPassword,setResetPassword] = useState(false);
  const [resetEmail ,setResetEmail] =  useState('');

  

  const user_Authentication=async()=>{
    // navigateToNext('/home');
   console.log("userAUTH");
   console.log('userid',userId,"password",password);
   
      const loginResponse = await login_User(userId,password);
      console.log(loginResponse);
      if(loginResponse.data){
        const token = loginResponse.data.token;
        const username = loginResponse.data.name;
        
        updateToken(token); //context for storing token in the Authcontext using use context hook

        const cookieData = {
          token:token,
          username:username
        }
        const cookiDataString  = JSON.stringify(cookieData);
  
        // sessionStorage.setItem("token",loginResponse.data.token);
        // localStorage.setItem("name",loginResponse.data.name);
        // alert(loginResponse.data.message);
        // Cookies.set('token', token, { path: '/', httpOnly: true });
        Cookies.set('token',cookiDataString,{path:'/'});
        toast.success(loginResponse.data.message);
        navigateToNext(`/home/${token}`)
      }
      else{
        toast.error(loginResponse.response.data.message);
      }
  }

  // navigation to next page
  const navigateToNext=(path)=>{
    navigate(path);
  }

  const googleSignIN =()=>{
    window.open('https://forms.ictkerala.org/auth/google',"_self");
  }

  const handle_Forgot_Password=()=>{
    setOpenEmail(true);
  }

  const sendEmailForOTPVerification=async(email)=>{
    console.log("ghusd",email)
    setResetEmail(email);
    
      const forgotPassword = await forgot_Password(email);

      console.log(forgotPassword);
      if(forgotPassword.data){
        //alert(forgotPassword.data.message);
      toast.info(forgotPassword.data.message);
      setOpenEmail(false);
      setResetPassword(true);
      }
      else{
        toast.error(forgotPassword.response.data.message);
      }
  }

  return (
    <>
      <div className="login-inner-title-container">
        <Typography variant="h4" style={{ fontWeight: "900" }}>
          SIGN IN
        </Typography>
        <img
          className="google-signup"
          src={require("../../../images/google.png")}
          onClick={googleSignIN}
        />

        <p style={{ marginTop: "30px" }}> or use your account</p>
      </div>
      <div className="login-inner-text-field-container">
        <TextField
          className="login-textfield"
          // placeholder='Email'
          label="Email"
          type="email"
          value={userId}
          onChange={(e)=>{setUserId(e.target.value)}}
        />
        <TextField
          className="login-textfield"
          // placeholder='Email'</>
          label="Password"
          type="password"
          value={password}
          onChange={(e)=>{setPassword(e.target.value)}}
        />
        <p className="forgot-password" onClick={handle_Forgot_Password}>Forgot your passsword?</p>

        <Buttton18 Click={user_Authentication}/>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Email_Dialog_Box
      open={openEmail}
      handleClose={()=>{setOpenEmail(false)}}
      sendEmail={sendEmailForOTPVerification}
      />
      <PasswordReset
      open={resetPassword}
      handleClose={()=>{setResetPassword(false)}}
      email={resetEmail}
      />
    </>
  );
};

export default Login_Box;
