import React, { useEffect, useState } from 'react';
import './regstyle.css';
import TableCustom from '../../components/Tables/Table';
import { fetchThefile_from_server, registration_Data_fetch } from '../../API/Fetch_data';
import Data_empty from '../../container/DataNotFound/Data_empty';
import { ToastContainer, toast } from 'react-toastify';

const Reg_User = ({ formId }) => {
  const Token = sessionStorage.getItem('token');
  
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);


  useEffect(() => {
    fetchRegUserData();
  }, [])

const fetchRegUserData = async () => {
  console.log("Token",Token);
    const reg_data = await registration_Data_fetch(formId,Token);
    console.log("reg data____", reg_data);
    if (reg_data.data && reg_data.data.length >0) {
      const userData = reg_data.data.map(entry => entry.dynamicFields.userData);
      const userFiles = reg_data.data.map(entry=>entry.dynamicFields.files);
      
      console.log("user____________data", userData);
     // Process user data
const userValues = [];
userData.forEach(entry => {
  const tempValues = {};
  entry.forEach(obj => {
    for (const key in obj) {
      tempValues[key] = obj[key];
    }
  });
  userValues.push(tempValues);
});

// Process file data
const fileValues = [];
userFiles.forEach((file, index) => {
  const tempFileValues = {};
  file.forEach(obj => {
    for (const key in obj) {
      tempFileValues[key] = obj[key];
    }
  });
  fileValues[index] = tempFileValues;
});

// Combine user and file data into final values array
const finalValues = [];
userValues.forEach((userData, index) => {
  finalValues.push({ ...userData, ...fileValues[index] });
});

// Extract keys from combined data
const finalKeys = Object.keys(finalValues[0]);
const extractvalues = finalValues.map(obj=>Object.values(obj));

console.log("Keys:", finalKeys);
console.log("Values:", finalValues);

setColumns(finalKeys);
setRows(extractvalues);

      // setFilerows(filevalues);
      // setFileColumns(filekeys);
      
    }
  }

  const exportToCSV = () => {
    const csvContent = [
      columns.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // file download
  const download_file=async(filename)=>{
    console.log("filename",filename);
    const filefetch = await fetchThefile_from_server(filename);
    console.log(filefetch);
    if(filefetch.data){
      const url = window.URL.createObjectURL(new Blob([filefetch.data]));

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); // Set the download attribute
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Cleanup: Remove the temporary anchor element
      link.parentNode.removeChild(link);
  
    }
  else{
      toast.error("Somthing went wrong, try again after sometime");
    }
  }

  return (
    <>
   {columns.length ==0 ?
      <Data_empty/>
      :
    <div className='reg-user-container'>
      <div className='reg-table-container'>
        <button style={{marginBottom:'10px'}} className="button-15" role="button" onClick={exportToCSV}>Export To CSV</button>
        {/* <div> */}
        <TableCustom tableColumns={columns} tableRows={rows} download_file={download_file} />
        {/* </div> */}
        
        {/* {fileColumns.length !==0 &&
       <div>
          <TableCustom tableColumns={fileColumns} tableRows={filerows} type={'file'}  />
       </div>
        
        } */}
      </div>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
  }
    </>
  )
}

export default Reg_User;
