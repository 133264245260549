import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './card-custom.css';

const CardCustom = ({HandleClick}) => {
  return (
    <Card className='card-custom-main-container' onClick={HandleClick}>
        <CardMedia
        // style={{backgroundColor:'ButtonHighlight'}}
        sx={{ height: 150,width:150 }}
        image={require('../../images/addicon.png')}
        title="Create Form"
      />
      <CardContent>
      <Typography style={{fontWeight:'bold',fontSize:24,color:'#8b008b'}} >New</Typography>
      </CardContent>
      
    </Card>
  )
}

export default CardCustom
