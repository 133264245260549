import axios from "axios";

import { Url_fetch } from "../UrlConnection/Url";

const url = Url_fetch();


export const fetchAllFormsDataWithUSer =async()=>{
    try{
        const forms = await axios.get(`/superAdmin/fetch_allform`)
        console.log(forms);
        return forms;
    }
    catch(error){
        console.error(error);
        return await error;
    }
}

// admin token verification 
// http://localhost:4002/superAdmin/admin-token-verify
export const AdminTokenVerify =async(adminToken)=>{
    console.log("adminToken________________$$$$S",adminToken);
    try{
        const headers = {authorization:adminToken}
        console.log("headers", headers)
        const tokenResponse = await axios.post(`/superAdmin/admin-token-verify`,{},{"headers":headers})

        return tokenResponse;
    }
    catch(error){
        console.error(error);
        return await error;
    }
}