import React from 'react'
import './button.css';
const Button19 = ({ClickButton}) => {
  return (
    <>
      <button class="button-19" role="button" onClick={ClickButton}>Sign Up</button>
    </>
  )
}

export default Button19
