import React, { useState } from 'react'
import AuthContext from './AuthContext';

const TokenProvider = ({children}) => {
  const [token,setToken] = useState(null);

  const updateToken =(newToken)=>{
    setToken(newToken);
  }
    return (
    <AuthContext.Provider value={{token,updateToken}}>
        {children}
    </AuthContext.Provider>
  )
}

export default TokenProvider
