import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './dialog.css';
import { Checkbox, List,ListItem, ListItemText, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { CheckBox } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function Updating_Form_Widgets({
  widgetType,
  opendailog,
  handleSave,
  handleClose,
  widget_Id,

    widget_Data
}) {
  
  const [widgetField, setWidgetField] = useState({
    name: '',
    label: '',
    type: '',
    required: false,
    listArray: [],
    fileTypeArray: [],
    unique: false,
    filesize:'',
  });


  // validation 
  const [validationErrors, setValidationErrors] = useState({
    label: '',
    name: '',
    type: '',
  });


  useEffect(() => {
    if (widget_Data) {
      setWidgetField({
        name: widget_Data.name,  
        label: widget_Data.label,
        type: widget_Data.type,
        required: widget_Data.required,
        listArray: widget_Data.listItems || [], 
        fileTypeArray: widget_Data.fileType || [],
        unique: widget_Data.unique,
        filesize:widget_Data.fileSize,
      });
    }
  }, [widget_Data]);

  const handleOnChange=(e)=>{
    const {name,value} = e.target;

    // check the field is not null or numeric
    let error = '';
    if (value.trim() === '') {
      error = 'This field is required';
    } else if ( !isNaN(value)) {
      error = 'Must not be numeric';
    }

    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: error }));

    setWidgetField(prevsate=>({...prevsate,[name]:value}));
    console.log(widgetField);

  }

  const handleSwitchChange = () => {
    // Toggle the state of isRequired when the switch is changed
    setWidgetField((prevRequired) =>({...prevRequired,['required']:!widgetField.required}));
    console.log("array",widgetField);
  };

  // change the unique state 
  const handleSwitchChangeOfUnique = () => {
    // Toggle the state of isRequired when the switch is changed
    setWidgetField((prevUinque) =>({...prevUinque,['unique']:!widgetField.unique}));
    console.log("array",widgetField);
  };

  const handleAddToList = () => {
    // Add the current value to the listArray
    setWidgetField((prevState) => ({
      ...prevState,
      listArray: [...prevState.listArray, widgetField.item],
      item: '', // Clear the label after adding to the list
    }));

    console.log("listeincluded ",widgetField);
  };

  // remove the item from list
  const handleRemoveFromList = (index) => {
    // Remove the item at the specified index from the listArray
    setWidgetField((prevState) => ({
      ...prevState,
      listArray: prevState.listArray.filter((_, i) => i !== index),
    }));
  };

  // handle file size dropdown list
  const handleOnChangeFileSize=(e)=>{
    const{name,value} =  e.target;
    console.log("name",name,"value",value);
    setWidgetField({...widgetField,[name]:value})
  
  }
 

  // render the component based on the widget type
const renderAddListComponent=()=>{
  switch(widgetType){
 
    case 'radio':
      return(
        // i want a button + icon for adding list of data it is store to a listArray
        <>
            <label>Add List Item</label>
            <div className="list-container">
              <TextField
                className="text-padding-dialog"
                label="Add Item"
                name="item"
                value={widgetField.item}
                onChange={handleOnChange}
              />
              <AddIcon onClick={handleAddToList} style={{
                fontSize:27,
                color:'blue',
                margin:20,
                cursor:'pointer'
              }} />
            </div>
            <List id="remove-icon-list">
            {widgetField.listArray.map((item, index) => (
              <ListItem key={index} id="list-item">
                {item} 
                <RemoveIcon
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => handleRemoveFromList(index)}
                />
              </ListItem>
            ))}
          </List>
          </>
      )
    case 'list':
      return(
        <>
         <label><span className='span-color'>*</span> Select the type</label>
             <Select
              className="text-padding-dialog"
              fullWidth
              label="Widget Type"
              name="type"
              value={widgetField.type}
              onChange={handleOnChange}
              // error={Boolean(validationErrors.type)}
            >
              <MenuItem value="single">Single Select</MenuItem>
              <MenuItem value="multiple">Multiple Select</MenuItem>
            </Select>

          <label>Add List Item</label>
            <div className="list-container">
              <TextField
                className="text-padding-dialog"
                label="Add Item"
                name="item"
                value={widgetField.item}
                onChange={handleOnChange}
              />
              <AddIcon onClick={handleAddToList} style={{
                fontSize:27,
                color:'blue',
                margin:20,
                cursor:'pointer'
              }} />
            </div>
            <List id="remove-icon-list">
            {widgetField.listArray.map((item, index) => (
              <ListItem key={index} id="list-item">
                {item} 
                <RemoveIcon
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => handleRemoveFromList(index)}
                />
              </ListItem>
            ))}
          </List>
        </>
      )
    case 'text':
      return(
          <>
      
        <label>Type</label>
        <Select
          className="text-padding-dialog"
          fullWidth
          label="Widget Type"
          name="type"
          value={widgetField.type}
          onChange={handleOnChange}
          error={Boolean(validationErrors.type)}
        >
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="number">Number</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="mobile">Mobile</MenuItem>
        </Select>
        <>
        <DialogContentText id="widget-details-dialog-description">
          Is Unique:
        </DialogContentText>
        <FormControlLabel
          control={
            <Switch
              checked={widgetField.unique}
              onChange={handleSwitchChangeOfUnique} // Add the onChange handler
            />
          }
          label="Unique"
        />
      {/* </DialogContent> */}
        </>
        </>
      )
      case 'date':
        return(
          <>
           <label>Type</label>
      
        <Select
          className="text-padding-dialog"
          fullWidth
          label="Widget Type"
          name="type"
          value={widgetField.type}
          onChange={handleOnChange}
          error={Boolean(validationErrors.type)}
        >
          <MenuItem value="date">Date</MenuItem>
          <MenuItem value="date&time">Date & Time</MenuItem>
        </Select>
          </>
         
        )

      case 'file':
        return(
          <>
          <Select
          labelId='demo-multiple-checkbox-label'
          id="demo-multiple-checkbox"
          multiple
          value={widgetField.fileTypeArray}
          onChange={(e) => {
            const selectedFileTypes = e.target.value;
            setWidgetField((prevState) => ({ ...prevState, fileTypeArray: selectedFileTypes }));
          }}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(',')}
         
        >
          <MenuItem value="image">
            <Checkbox checked={widgetField.fileTypeArray.includes("image")} />
            <ListItemText primary={"image"} />
          </MenuItem>
          <MenuItem value="pdf">
            <Checkbox checked={widgetField.fileTypeArray.includes("pdf")} />
            <ListItemText primary={"pdf"} />
          </MenuItem>
          <MenuItem value="docs">
            <Checkbox checked={widgetField.fileTypeArray.includes("docs")} />
            <ListItemText primary={"docs"} />
          </MenuItem>
          <MenuItem value="ppt">
            <Checkbox checked={widgetField.fileTypeArray.includes("ppt")} />
            <ListItemText primary={"PPT"} />
          </MenuItem>
          <MenuItem value="pptx">
            <Checkbox checked={widgetField.fileTypeArray.includes("pptx")} />
            <ListItemText primary={"PPTX"} />
          </MenuItem>
        </Select>

        <Select
          className="text-padding-dialog"
          fullWidth
          placeholder='Select the file size'
          label="Widget Type"
          name="filesize"
          value={widgetField.filesize}
          onChange={handleOnChangeFileSize}
          // error={Boolean(validationErrors.type)}
        >
          <MenuItem value="1">1MB</MenuItem>
          <MenuItem value="2">2MB</MenuItem>
          <MenuItem value="5">5MB</MenuItem>
          <MenuItem value="10">10MB</MenuItem>

        </Select>

       </>
        )
      case'label':
      return(
        <>
           <label><span className='span-color'>*</span>Text Type</label>
          
          <Select
            className="text-padding-dialog"
            fullWidth
            label="Widget Type"
            name="type"
            value={widgetField.type}
            onChange={handleOnChange}
            error={Boolean(validationErrors.type)}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="sub-title">Sub title</MenuItem>
            <MenuItem value="title">Title</MenuItem>
          </Select>
        </>
      )
      default:
        return null;
  }
}

// handle save the data
const handleToSavetheData = () => {
  console.log("validationError", validationErrors);
  console.log("fielddata", widgetField);

  // Check if there are no validation errors
  
  if (
    !validationErrors.label &&
    !validationErrors.name &&
    !validationErrors.type 
  ) {
    if(widgetField.label !== '' || widgetField.type !== ''){
    // If no errors, call the handleSave function
    const widgetFieldWithId = { ...widgetField, _id: widget_Id }; // Add _id field
    handleSave(widgetFieldWithId, widgetType, widget_Id);
    setWidgetField({
      name:'',  
      label:'',
      type:'',
      required:false, // to store required status
      listArray: [], // Array to store list data
      fileTypeArray:[],
      filesize:'',

    })
    
    handleClose();
  
  }
  else{
    alert("All Fields are required");
  }
}

};


  return (
    <Dialog
      open={opendailog}
      // TransitionComponent={Transition}
      // keepMounted
      onClose={handleClose}
      aria-describedby="widget-details-dialog-description"
      disableEscapeKeyDown
      disableBackdropClick
     
    >
      <DialogTitle>
        {widgetType.toUpperCase()}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
           <CloseIcon />
        </IconButton>
      
      </DialogTitle>
      <DialogContent style={{display:'flex',flexDirection:'column'}}>
      <label>Label</label>
        <TextField
          className="text-padding-dialog"
          label="Label"
          name="label"
          fullWidth
          value={widgetField.label}
          onChange={handleOnChange}
          helperText={validationErrors.label}
          error={Boolean(validationErrors.label)}
        />
        {widgetType !== 'label' &&
         <>
          <label>Name</label>
        <TextField
          className="text-padding-dialog"
          label="Name"
          fullWidth
          name="name"
          value={widgetField.name}
          onChange={handleOnChange}
          helperText={validationErrors.name}
          error={Boolean(validationErrors.name)}
        />
         </>
}
       

       
       { renderAddListComponent()}
       {widgetType !== 'label' &&
       <>
        <DialogContentText id="widget-details-dialog-description">
          Is Required:
        </DialogContentText>
        <FormControlLabel
          control={
            <Switch
              checked={widgetField.required}
              onChange={handleSwitchChange} // Add the onChange handler
            />
          }
          label="Required"
        />
       </>
      }
       
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleToSavetheData}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
