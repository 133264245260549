import React from 'react';
import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 10,
  },
  table: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    border: '1px solid #000',
  },
  tableHeader: {
    borderBottomColor: '#000',
    borderBottomWidth: 1,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    width: '20%',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    padding: 5,
  },
});

const PdfTable = ({ rows }) => {

  const convertToDateFormat = (unformattedDate) => {
    const date = new Date(unformattedDate);
    const formatDate = date.toLocaleDateString('en-GB');
    return formatDate;
  };
  
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={[styles.tableCell, styles.tableHeader]}>
            <Text>Number</Text>
          </View>
          <View style={[styles.tableCell, styles.tableHeader]}>
            <Text>Name</Text>
          </View>
          <View style={[styles.tableCell, styles.tableHeader]}>
            <Text>Email</Text>
          </View>
          <View style={[styles.tableCell, styles.tableHeader]}>
            <Text>Form Title</Text>
          </View>
          <View style={[styles.tableCell, styles.tableHeader]}>
            <Text>Created</Text>
          </View>
          <View style={[styles.tableCell, styles.tableHeader]}>
            <Text>Count</Text>
          </View>
          <View style={[styles.tableCell, styles.tableHeader]}>
            <Text>Status</Text>
          </View>
        </View>
        {rows.map((row, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableCell}>
              <Text>{index + 1}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>{row.parentForm.userId.name}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>{row.parentForm.userId ? row.parentForm.userId.email : '-'}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>{row.parentForm.formTitle}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>{convertToDateFormat(row.parentForm.createdAt)}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>{row.participantCount}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>
                {row.parentForm.status === '0' && 'Active'}
                {row.parentForm.status === '1' && 'Closed'}
                {row.parentForm.status === '2' && 'Deleted'}
              </Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default PdfTable;
