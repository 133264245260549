import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultipleSelectList({datalist,name,handleChangeList}) {
  const [personName, setPersonName] = React.useState([]);
  const selectedList = React.useRef([]);
  

  const handleChange = (event) => {
   
      const{name,value} = event.target;
      const result = {[name]:value}
      console.log("name_list____",name,"%%%",value);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
   handleChangeList(result);
    
  };

  return (
    // <div>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          name={name}
          multiple
          value={personName}
          onChange={handleChange}
          style={{width:'93%'}}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
        //   MenuProps={MenuProps}
        >
          {datalist.map((data) => (
            <MenuItem key={data} value={data}>
              <Checkbox checked={personName.indexOf(data) > -1} />
              <ListItemText primary={data} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    // </div>
  );
}