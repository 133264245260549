// RowRadioButtonsGroup.js
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioButton({name,handleRadioChange,listdata,label}) {
    const handleChange=(e)=>{
      const select = e.target.value;
      const result = { [name]: select };
      handleRadioChange(result);

    }
  return (
    <FormControl>
       {/* <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>  */}
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        onChange={handleChange}
      >
        {listdata.map((data)=>(
            <FormControlLabel value={data} control={<Radio />} label={data} />
        ))}
        
        {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
       
      </RadioGroup>
    </FormControl>
  );
}