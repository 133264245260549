import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import './style.css';
import Reg_User from '../RegisterdUser/Reg_User';
import Form_Settings from '../FormSettings/Form_Settings';
import Form_Update from '../UpdateForm/Form_Update';
import { useLocation } from 'react-router-dom';
import AppBarCustom from '../../container/Appbar/AppBar';
import AppbarAdmin from '../../supperAdmin/Appbar/Appbar';

const ViewForms = () => {
  const location  = useLocation();

    const {formId,user} = location.state;

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  useEffect(()=>{
    console.log("FormId_________",formId);
  },[])

const renderTabContent=(tabvalue)=>{
    console.log("selected tab",tabvalue);
    switch(tabvalue){
        case '1':
            return (<Form_Update formId={formId}/>)
        case '2':
            return ( <Reg_User formId={formId}/>)
        case '3':
            return (<Form_Settings formId={formId}/>)
        default:
            return null;
    }

}
  return (
    <>
    {user==='admin'?<AppbarAdmin/> :<AppBarCustom/>}
    
      <Box sx={{ width: '100%', typography: 'body1'}} >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider',display:'flex',justifyContent:"center"}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Form" value="1" />
            <Tab label="Registration" value="2" />
            <Tab label="Settings" value="3" />
          </TabList>
        </Box>
      {renderTabContent(value)}
      </TabContext>
    </Box>



    
    </>
    
  )
}

export default ViewForms
