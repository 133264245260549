import React from 'react'
import './appbarStyle.css';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { Typography } from '@mui/material';

const AppbarAdmin = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem('email');

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {

    setAnchorElUser(null);
  };

  const handleLogOut=()=>{
    sessionStorage.removeItem('token');
    navigate('/admin');
  }

  const [anchorElUser, setAnchorElUser] = useState(null);

  return (
    <div className='appbar-container'>
      <div className='icon-block'>
        <img onClick={()=>{navigate('/adminhome')}} className='icon-img' src={require('../../images/LOGO_ICTAK-ENG-ALT-White-Text.png')} />

      </div>
      <div className='midle-block'>
       

      </div>
      <div className='end-block'>
      <div className='profile-circle'  onClick={handleOpenUserMenu}>
            <img className='profilre-pic' src={require('../../images/user2.png')} />
            
        </div>
        <p className='name-text'>{email}</p>
        <Menu
          sx={{ mt: '45px' }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          
            <MenuItem  onClick={handleCloseUserMenu}>
              <Typography onClick={handleLogOut} textAlign='center'>{'Log Out'}</Typography>
            </MenuItem>
           
          
        </Menu>
      </div>
    </div>
  )
}

export default AppbarAdmin;
