import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { validateEmail,validateMobile,validateText } from '../../Validation/Validation';
import './signupStyle.css';
import { userSignUp } from '../../API/Register';
import { ToastContainer, toast } from 'react-toastify';

const SignUp = () => {
    const [user , setUser] = useState({
        name:'',
        mobile:'',
        email:'',
        password:'',
        confirmpassword:'',
    });

    const [errors , setErrors] = useState({});

    const navigate = useNavigate();

   const navigationToNext=(path)=>{
    navigate(path);
   }

   const handleChangeText=(e)=>{
    console.log(errors)
    const {name,value} = e.target;
    console.log("p",name);
    setErrors({ ...errors, [name]: '' });

  const errorCheck = {...errors};
    switch(name){
        case 'name':
            errorCheck.name = validateText(value)?'':'Please enter a valid name';
            break;
        case 'mobile':
            errorCheck.mobile = validateMobile(value) ? '' : 'Please enter a valid mobile number';
            break;
        case 'email':
            errorCheck.email = validateEmail(value) ? '' : 'Please enter a valid email address';
            break;
            case 'password':
                errorCheck.password = '';
                break;
        case 'confirmpassword':
            errorCheck.confirmpassword = user.password === value ? '' : 'Passwords do not match';
            break;

        default:
            // if(!value){
            //     errorCheck[name] = 'Cannot be null';
            // }
        break;
    }   
    setErrors(errorCheck)
   
    setUser({...user,[name]:value})

    
   }
    
// const defaultTheme = createTheme();
const handleSubmitRegistration=async(e)=>{
    e.preventDefault();
    console.log("userData",user);
    const fieldErrors = {};
    Object.entries(user).forEach(([fieldName, fieldValue]) => {
        if (!fieldValue.trim()) {
            // If field is empty, set error message
            fieldErrors[fieldName] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`;

        }
    });

    // Update errors state with field-specific errors
    setErrors({ ...errors, ...fieldErrors });

    // if(user.name === ''|| user.mobile === ''|| user.email == ''|| user.password === '' || user.confirmpassword === ''){
    
    // }
    // else{

    
    if (Object.values(errors).every(error => error === '')) {
        console.log("Submsion success");
        
        const signUpdata = await userSignUp(user);
    console.log(signUpdata);
    if(signUpdata.data){
      toast.success(signUpdata.data.message)
      navigationToNext('/');
    }
    else{
      if(signUpdata.response.data.message){
            toast.error(signUpdata.response.data.message)
          }
          else{
            toast.error(signUpdata.response.data.errors[0].msg);
          }
    }
    
    }
    

}
return (
<div className='signup-form-Outercontainer' >
    <div className='signup-form-inner-container'>
    {/* <ThemeProvider theme={defaultTheme}> */}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmitRegistration}  sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  value={user.name}
                  label="First Name"
                  autoFocus
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChangeText}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  type='number'
                  label="Mobile"
                  name="mobile"
                  value={user.mobile}
                  onChange={handleChangeText}
                //   autoComplete="family-name"
                error={!!errors.mobile}
                helperText={errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={user.email}
                  onChange={handleChangeText}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={user.password}
                  onChange={handleChangeText}
                  error={!!errors.password}
                  helperText={errors.password}
                 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmpassword"
                  label="ConfirmPassword"
                  type="password"
                  id="confirmpassword"
                  value={user.confirmpassword}
                  onChange={handleChangeText}
                //   autoComplete="new-password"
                error={!!errors.confirmpassword}
                helperText={errors.confirmpassword}
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              
                 <p>Already have an account? <span onClick={()=>{navigationToNext('/')}} className='sign-in-text'>Sign in</span> </p> 
                
              </Grid>
            </Grid>
          </Box>
        </Box>
      
      </Container>
    {/* </ThemeProvider> */}

    </div>
    <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
  )
}

export default SignUp
